import { Box, Button, Stack, Typography } from '@mui/material';
import Page from 'component/new_design/layout/Page';
import { SitesTable } from '../partial/tables/sites/SitesTable';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreateSiteModal } from '../partial/CreateSite/CreateSiteModal';
import { useState } from 'react';
import { DashboardMetrics } from '../partial/DashboardMetrics/DashboardMetrics';
import { MetricsSkeleton } from '../partial/DashboardMetrics/MetricsSkeleton';
import { DashboardUpdates } from '../partial/DashboardUpdates';
import { DashboardSupport } from '../partial/DashboardSupport';
import { SuspenseLoader } from 'component/new_design/base/SuspenseLoader';
import { linkHelper } from 'linkHelper';
import Plus from 'component/new_design/icons/Plus.svg?react';
import { RoleGuard } from 'component/base/RoleGuard';

export default function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isCreateSiteOpen, setIsCreateSiteOpen] = useState(false);

  return (
    <>
      <Page>
        <Typography variant="h1" mb={6}>
          {t('dashboard')}
        </Typography>
        <Stack gap="var(--dashboard-gap)">
          <SuspenseLoader loading={<MetricsSkeleton />} errorMessage={t('metrics_loading_error')}>
            <DashboardMetrics />
          </SuspenseLoader>

          <SitesTable enablePagination={false} enableSearch={false}>
            <Button
              onClick={() => navigate(linkHelper.newDesign.sites.getLink())}
              size="medium"
              variant="contained"
              color="secondary"
            >
              {t('see_all')}
            </Button>
            <RoleGuard roles={['billing_admin']} type="block">
              <Button
                onClick={() => setIsCreateSiteOpen(true)}
                size="medium"
                variant="contained"
                color="primary"
                startIcon={<Plus />}
              >
                {t('create_site')}
              </Button>
            </RoleGuard>
          </SitesTable>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: [undefined, undefined, undefined, '1fr 1fr'],
              gridAutoRows: 'minmax(300px, auto)',
              gap: 'var(--dashboard-gap)',
            }}
          >
            <DashboardSupport />
            <DashboardUpdates />
          </Box>
        </Stack>
      </Page>

      {isCreateSiteOpen ? <CreateSiteModal onCancel={() => setIsCreateSiteOpen(false)} /> : null}
    </>
  );
}
