import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export const MetricsGrid = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <Box
      component="section"
      aria-label={t('metrics')}
      sx={{
        display: 'grid',
        gap: 'var(--dashboard-gap)',
        gridTemplateColumns: [undefined, 'repeat(2, 1fr)', undefined, 'repeat(4, 1fr)'],
        gridAutoRows: 'minmax(120px, auto)',
      }}
    >
      {children}
    </Box>
  );
};
